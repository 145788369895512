<template>
  <div>
    <dashboard-page-title :showAddBtn="false">المراجعات</dashboard-page-title>
    <main-table :baseURL="VUE_APP_MARKETPLACE_LINK" :statusUrl="'allowed-vehicle-types/status/'"  :fields="fields" list_url="admin/reviews"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_MARKETPLACE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_MARKETPLACE_LINK,
      fields: [
        { label: 'صوره المنتج', key: 'product.featured_image', class: 'text-right', type: 'image' },
        { label: 'اسم المنتج', key: 'product.name', class: 'text-right' },
        { label: 'اسم المتجر', key: 'store.name', class: 'text-right' },
        { label: 'التعليق', key: 'comment', class: 'text-right' },
        { label: 'التقييم', key: 'star', class: 'text-right' },
        { label: 'االحالة', key: 'status', class: 'text-right', type: 'status' },
        { label: 'Created At', key: 'created_at', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'Delete',
              icon: 'ri-delete-bin-line',
              color: 'danger',
              url: 'admin/reviews',
              titleHeader: 'name',
              textDeleteButton: 'بالتأكيد, مسح',
              textCancelButton: 'الغاء',
              showAlert: true
            }
          ]
        }
      ]
    }
  },
  methods: {}
}
</script>
